import {
  PlanCategoryInterface,
  PlanOptionInterface,
  PlanOptions,
  PlansGrid,
} from '@rabbit/sage/views/PlansAndPricesView';
export const PlanData = {
  header: ['1 year', '2 years', '3 years', '5 years'],
  options: [
    {
      title: 'Bronze',
      values: ['0 repairs', '0 repairs', '0 repairs', '0 repairs'],
    },
    {
      title: 'Silver',
      values: [
        '2 minor repairs',
        '4 minor repairs',
        '6 minor repairs',
        '10 minor repairs',
      ],
    },
    {
      title: 'Gold',
      values: [
        '2 minor, 1 major',
        '4 minor, 2 major',
        '6 minor, 3 major',
        '10 minor, 5 major',
      ],
    },
    {
      title: 'Platinum',
      values: ['Unlimited*', 'Unlimited*', 'Unlimited*', 'Unlimited*'],
    },
  ],
};

export type PlanDataShape = typeof PlanData;

export function PlansTab() {
  const plans: PlanCategoryInterface[] = [
    { title: 'Bronze', description: '1,2,3 years', start: 160 },
    { title: 'Platinum', description: '1,2,3 years' },
    { title: 'Diamond', description: '1,2,3 years' },
    { title: 'Gold', description: '1,2,3 years' },
  ];
  const options: PlanOptionInterface[] = [
    {
      title: 'Advice guides',
      values: [
        'Basic do’s & don’ts',
        'Full care guides & trouble shooting',
        'Full care guides & trouble shooting',
        'Full care guides & trouble shooting',
      ],
    },
    {
      title: 'Discount on products & services',
      values: ['5%', '10%', '15%', '20%'],
    },
    {
      title: 'Is clean & gleam** included with call out',
      values: [
        false,
        'Surcharge of $500 + GST',
        'Surcharge of $300 + GST',
        true,
      ],
    },
    {
      title: 'Quarterly newsletter',
      values: [true, true, true, true],
    },
    {
      title: 'Acid ETCH removal on sensitive stone',
      values: [false, '10% Discount', '15% Discount', '20% Discount'],
    },
    {
      title: 'Is the plan upgradable?',
      values: [true, true, true, 'Not applicable'],
    },
  ];

  return (
    <>
      {/*<PlansHeader
        title="Plans and benefits"
        description="Updated: 22/04/2024"
        plans={plans}
        />*/}
      <PlansGrid plans={PlanData} />
      <div className="font-caudex text-md text-md mt-4">
        <strong>Minor repair</strong>: chips, blemishes, small cracks (where
        face polishing is not required).
        <br />
        <strong>Major repair</strong>: structural repairs including joints,
        dropped sinks, scratch and crack repairs (where face polishing is
        required). Other repairs by assessment.
      </div>
      <div className="p-4"></div>
      <PlanOptions options={options} />

      <div className="font-caudex mt-4 text-black">
        * Unlimited repairs subject to our fair use policy.
        <br />
        ** Clean & Gleam is a complete check, repair and clean service.
        <br />
      </div>
    </>
  );
}
