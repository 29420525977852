import { PencilIcon, PlusIcon } from '@heroicons/react/24/solid';
import {
  Button,
  ButtonIcon,
  ButtonLink,
  CardWrapperWithHeader,
  ChevronButton,
  CloudUploadIcon,
  formatPhoneNumber,
  formatUnixTime,
  Heading,
  Input,
  LoadingSpinner,
  Modal,
  ModalSettingsShape,
  Table,
} from '@rabbit/elements/shared-components';
import {
  Address,
  DTRepairer_Public,
  Permissions,
  UserUploadedDocument,
} from '@rabbit/data/types';
import { getUserPermissions, printAddress } from '@rabbit/sage/utils/helpers';
import { useContext, useEffect, useRef, useState } from 'react';
import shopifyLogo from '../../../assets/images/shopify-logo.png';
import imagePlaceholder from '../../../assets/images/profile-placeholder.png';
import { ChangePasswordModal } from './ChangePasswordModal/ChangePasswordModal';
import { EditAddAddressModal } from './EditAddAddressModal/EditAddAddressModal';
import { EditNameModal } from './EditNameModal/EditNameModal';
import { useFileStorage, useManageSageProfile } from '@rabbit/bizproc/react';
import { Form, Formik, FormikProps } from 'formik';
import { Tenant } from '@rabbit/bizproc/client';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ChangePhoneModal } from './ChangePhoneModal/ChangePhoneModal';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '@rabbit/sage/context/UserContext.tsx';
import { AVAILABLE_LANGUAGE_CODES } from '@rabbit/sage/utils/consts.ts';
import { AppContext } from '@rabbit/app-context';

// TODO: Update with actual tenant shape
type Tenant = {
  name: string;
  logo: string;
  addresses: Address[];
};

export interface InformationTabProps {
  kind: 'user' | 'tenant';
  user?: DTRepairer_Public;
  tenant?: Tenant;
}

interface FormValuesShape {
  tentant_logo: UserUploadedDocument | null;
}

export default function InformationTab({
  user,
  tenant,
  kind,
}: InformationTabProps) {
  const [addresses, setAddresses] = useState(
    user ? user.addresses : tenant?.addresses
  );
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const { tenantInfo } = useContext(AppContext);
  const tenantLink = t('tenantLink');
  const userPermissions = getUserPermissions(tenantLink, userContext);

  const defaultAddress =
    addresses && addresses?.length > 0
      ? addresses.find((i) => i.isDefault)
      : undefined;
  const userPicture = kind === 'tenant' ? tenant?.logo : user?.photoUrl;

  const [step, setStep] = useState<'address' | 'shopify' | 'default'>(
    'default'
  );
  const [addressModal, setAddressModal] = useState<'edit' | 'new' | undefined>(
    undefined
  );
  const [selectedAddress, setSelectedAddress] = useState<Address>({} as any);
  const [editNameModal, setEditNameModal] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [phoneModal, setPhoneModal] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const {
    updateProfileData,
    updateProfileImage,
    currentData,
    userActivePersonas,
  } = useManageSageProfile();
  const pictureInputRef = useRef<HTMLElement>();
  const [loading, setLoading] = useState(false);
  const getProperty = (key: string) => (t(key) !== key ? t(key) : null);

  const {
    uploadFiles,
    uploadedTempFiles,
    deleteFile,
    uploadTaskCat,
    clearFileFromState,
  } = useFileStorage();

  const historyTableHeaders = [
    {
      header: 'action',
      accessorKey: 'action',
      size: 15,
    },
    {
      header: 'customer',
      accessorKey: 'customer',
      size: 15,
    },
    {
      header: 'date',
      accessorKey: 'date',
      size: 15,
    },
  ];

  // Added any to get rid of warnings on FE, please update when convenient - DC
  const handleAddressModalSubmit = async (
    address: Address,
    mode: 'edit' | 'new',
    kind: 'user' | 'tenant' | 'partner'
  ) => {
    if (kind === 'user') {
      const oldAddresses =
        currentData?.[`${userActivePersonas[0]}_public`]?.addresses || [];
      let addressUpdate = [];
      if (mode === 'new') {
        address.id = oldAddresses.length + '';
        if (oldAddresses.filter((i: any) => i.isDefault).length === 0)
          address.isDefault = true;
        else if (address.isDefault)
          oldAddresses.forEach((a: any) => (a.isDefault = false));
        addressUpdate = [...oldAddresses, address];
      } else {
        addressUpdate = oldAddresses.map((i: any) => {
          if (address.isDefault && i.id !== selectedAddress.id)
            i.isDefault = false;
          if (i.id === selectedAddress.id) return address;
          else return i;
        });
        if (addressUpdate.filter((i: any) => i.isDefault).length === 0)
          addressUpdate[0].isDefault = true;
      }
      await updateProfileData({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: null,
        addresses: addressUpdate,
      });
      setAddresses(addressUpdate);
      toast.success('Address has been updated successfully');
      setTimeout(() => navigate('/manage-account'), 2000);
    } else {
      const config = getProperty('CFG_COBRAND_ADDRESSES');
      const oldAddresses = JSON.parse(config ?? '[]') as Address[];
      if (address.isDefault) {
        oldAddresses.forEach((a) => (a.isDefault = false));
      }
      let addressUpdate = [];
      if (mode === 'new') {
        address.id = oldAddresses.length + '';
        addressUpdate = [...oldAddresses, address];
      } else {
        addressUpdate = oldAddresses.map((i) => {
          if (i.id === selectedAddress.id) {
            return address;
          } else {
            return i;
          }
        });
      }
      if (!addressUpdate.some((i) => i.isDefault) && addressUpdate.length > 0) {
        addressUpdate[0].isDefault = true;
      }
      try {
        await Tenant.WriteConfigToTenantPublic(
          {
            CFG_COBRAND_NAME: t('CFG_COBRAND_NAME'),
            CFG_COBRAND_SHORT_NAME: t('CFG_COBRAND_SHORT_NAME'),
            CFG_COBRAND_LOGO: t('CFG_COBRAND_LOGO'),
            CFG_COBRAND_ADDRESSES: JSON.stringify(addressUpdate),
          },
          t('tenantLink')
        );
        toast.success(t('Address has been updated successfully'));
        setAddresses(addressUpdate);
        setTimeout(() => navigate('/my-organization'), 2000);
      } catch (e: any) {
        toast.error('Error: ' + e.message);
      }
    }
  };

  const confirmationModalSettings: ModalSettingsShape = {
    text: t('message.confirmDeleteAddress'),
    primaryButtonText: t('general.yes'),
    outlineButtonText: t('general.cancel'),
    handleClose: () => setConfirmDeleteModal(false),
    handlePrimaryClick: async () => await deleteAddress(selectedAddress),
    handleOutlineClick: () => setConfirmDeleteModal(false),
  };

  const handleAddressDelete = async (address: Address) => {
    setConfirmDeleteModal(true);
    setSelectedAddress(address);
  };

  // Added any to get rid of warnings on FE, please update when convenient - DC
  const deleteAddress = async (address: Address) => {
    if (kind === 'user') {
      const oldAddresses =
        currentData?.[`${userActivePersonas[0]}_public`]?.addresses || [];
      let remainAddresses = oldAddresses.filter(
        (i: any) => i.id !== address.id
      );
      // Check if there are no addresses with key isDefault
      const hasDefaultAddress = remainAddresses.some((i: any) => i.isDefault);
      if (!hasDefaultAddress && remainAddresses.length > 0) {
        remainAddresses = remainAddresses.map((i: any, index: number) => {
          if (index === 0) {
            i.isDefault = true;
          }
          return i;
        });
      }
      await updateProfileData({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: NaN,
        addresses: remainAddresses,
      });
      setAddresses(remainAddresses);
      setConfirmDeleteModal(false);
      toast.success(t('Address deleted successfully'));
    } else {
      const config = getProperty('CFG_COBRAND_ADDRESSES');
      const oldAddresses = JSON.parse(config || '[]') as Address[];
      const remainAddresses = oldAddresses.filter((i) => i.id !== address.id);
      // for each address in remainAddresses recalculate is from 0 to n
      remainAddresses.forEach((i, index) => {
        i.id = index + '';
      });
      // if address is default and there are still addresses in remainAddresses, set first address as default
      if (
        !remainAddresses.some((i) => i.isDefault) &&
        remainAddresses.length > 0
      ) {
        remainAddresses[0].isDefault = true;
      }
      try {
        await Tenant.WriteConfigToTenantPublic(
          {
            CFG_COBRAND_NAME: t('CFG_COBRAND_NAME'),
            CFG_COBRAND_SHORT_NAME: t('CFG_COBRAND_SHORT_NAME'),
            CFG_COBRAND_LOGO: t('CFG_COBRAND_LOGO'),
            CFG_COBRAND_ADDRESSES: JSON.stringify(remainAddresses),
          },
          t('tenantLink')
        );
        toast.success(t('Address has been deleted successfully'));
        setConfirmDeleteModal(false);
        setAddresses(remainAddresses);
      } catch (e: any) {
        toast.error('Error: ' + e.message);
      }
    }
  };

  const historyData = Array(100).fill({
    action: 'Order created',
    customer: 'daniel@iwarranty.co',
    date: formatUnixTime(new Date().getTime(), 'dd MMMM Y hh:mm a'),
  });

  const formikRef = useRef<FormikProps<FormValuesShape> | null>(null);

  const onUpdateLogo = async (url: string) => {
    await updateProfileImage(url);
    toast.success(t('Profile image has been updated successfully'));
    setLoading(false);
  };

  const saveTenantLogoToVeils = async (url: string) => {
    const res = await Tenant.WriteConfigToTenantPublic(
      {
        CFG_COBRAND_NAME: t('CFG_COBRAND_NAME'),
        CFG_COBRAND_SHORT_NAME: t('CFG_COBRAND_SHORT_NAME'),
        CFG_COBRAND_LOGO: url,
      },
      t('tenantLink')
    );
    //refresh page if logo changed to update logo in navbar -VP
    if (res?.mck?.CFG_COBRAND_LOGO) window.location.reload();
  };

  const onUpdatePicture = (url: string) => {
    if (typeof url === `string` && url.includes('http')) {
      if (kind === 'tenant') {
        void saveTenantLogoToVeils(url);
      }
      if (kind === 'user' && !loading && url !== userPicture) {
        setLoading(true);
        void onUpdateLogo(url);
      }
    }
  };

  const onUpdateTenantName = async (name: string) => {
    const res = await Tenant.WriteConfigToTenantPublic(
      {
        CFG_COBRAND_NAME: name,
        CFG_COBRAND_SHORT_NAME: name,
        CFG_COBRAND_LOGO: t('CFG_COBRAND_LOGO'),
      },
      t('tenantLink')
    );
    toast.success(t('Tenant name has been updated successfully'));
    if (res?.mck?.CFG_COBRAND_NAME) window.location.reload();
  };

  const languageCodes = AVAILABLE_LANGUAGE_CODES.map((data) => ({
    id: data.code,
    label: data.language,
    value: data.code,
  }));

  const handleLanguageChange = async (value: any) => {
    await Tenant.WriteConfigToTenantPublic(
      {
        CFG_COBRAND_NAME: t('CFG_COBRAND_NAME'),
        CFG_COBRAND_SHORT_NAME: t('CFG_COBRAND_SHORT_NAME'),
        CFG_COBRAND_LOGO: t('CFG_COBRAND_LOGO'),
        CFG_COBRAND_EMAIL_TEMPLATE_LANGUAGE: value.id,
      },
      t('tenantLink')
    );
    await i18n.changeLanguage(value.id);
    toast.success(t('Tenant Default Language has been updated successfully'));
  };

  const renderDefault = () => (
    <>
      <div className="mb-4 grid grid-cols-[1fr_auto] rounded-md border border-gray-300 p-5">
        <div className="flex">
          {currentData?.[`${userActivePersonas[0]}_public`]?.docid && (
            <div className="my-auto mr-5">
              <Formik
                onSubmit={async (values) => null}
                initialValues={{
                  tentant_logo: null,
                }}
              >
                <Input
                  key={'tenant_logo'}
                  type="picture"
                  name="tenant_logo"
                  settings={{
                    onRemove: () => onUpdatePicture(undefined as any),
                    onChange: (value: any) => onUpdatePicture(value),
                    personaId:
                      currentData?.[`${userActivePersonas[0]}_public`]?.docid ??
                      '',
                    externalRef: pictureInputRef,
                    placeholder: userPicture ? userPicture : imagePlaceholder,
                    uploadHandlers: {
                      category:
                        kind === 'tenant'
                          ? 'tenant_logo'
                          : 'repairer_profile_image',
                      uploadFiles,
                      uploadedTempFiles,
                      uploadTaskCat,
                      deleteFile,
                      clearFileFromState,
                    },
                  }}
                />
              </Formik>
            </div>
          )}
          <div>
            <Heading kind="h2" className="md:leading-[34px]">
              {/* Display a field to update tenant name if the tenant is a demo tenant */}
              {kind === 'tenant' &&
              currentData?.identity_private?.delegate?.some(
                (delegate: string) => delegate.includes('DEMO')
              ) ? (
                <Formik
                  onSubmit={(values) => {
                    if (values.tenant_name) {
                      void onUpdateTenantName(values.tenant_name);
                    }
                  }}
                  initialValues={{
                    tenant_name: tenant?.name,
                  }}
                >
                  <Form className="mb-2 flex w-full gap-2">
                    <Input
                      type="text"
                      name="tenant_name"
                      className="grow"
                      settings={{
                        placeholder: tenant?.name,
                      }}
                    />
                    <Button kind="primary" type="submit">
                      {t('Save')}
                    </Button>
                  </Form>
                </Formik>
              ) : kind === 'tenant' ? (
                tenant?.name
              ) : (
                user?.name
              )}
            </Heading>
            <div className="font-nunito mb-4 text-base capitalize text-gray-500">
              {kind === 'tenant' ? t('Organization') : ''}
            </div>
            <ButtonIcon
              kind="bgLightGreen"
              onClick={() => pictureInputRef.current?.click()}
              label={
                (userPicture ? t('Change ') : t('Add ')) +
                (kind === 'tenant' ? t('logo') : t('picture'))
              }
              Icon={CloudUploadIcon}
              iconLeft
            />
          </div>
        </div>
        {/* {kind === "tenant" && (
          <div className="self-center inline-flex px-4 py-4 rounded-lg border-gray-300 border gap-4 items-center">
            <img src={shopifyLogo} alt="shopify company logo" className="h-8" />
            <PillTime label="Connected" className="bg-green-100 text-green-800" />
          </div>
        )} */}
      </div>
      {kind === 'tenant' && (
        <div className="flex flex-col gap-4">
          <ButtonLink
            label={t('general.addresses')}
            onClick={() => setStep('address')}
          >
            {printAddress(defaultAddress)}
          </ButtonLink>
          <ButtonLink label={t('general.defaultCurrency')} disabled>
            {tenantInfo?.currency || ''}
          </ButtonLink>
          {/* <ButtonLink label="App management" onClick={() => setStep('shopify')}>
            <img src={shopifyLogo} alt="shopify company logo" className="h-[22px]" />
          </ButtonLink> */}
        </div>
      )}
      {kind === 'user' && (
        <div className="flex flex-col gap-4">
          <ButtonLink
            label={t('general.name')}
            onClick={() => setEditNameModal(true)}
          >
            {user?.name}
          </ButtonLink>
          <ButtonLink label={t('general.email')} disabled>
            {user?.email || ''}
          </ButtonLink>

          <ButtonLink
            label={t('general.phoneNumber')}
            onClick={() => setPhoneModal(true)}
          >
            {(user?.phone && formatPhoneNumber(user?.phone)) || ''}
          </ButtonLink>
          <ButtonLink
            label={t('general.password')}
            onClick={() => setPasswordModal(true)}
          >
            {t('general.changePassword')}
          </ButtonLink>
          <ButtonLink
            label={t('general.addresses')}
            onClick={() => setStep('address')}
          >
            {printAddress(defaultAddress)}
          </ButtonLink>
        </div>
      )}
      {userPermissions.includes(Permissions.Owner) && (
        <div className="mt-4 flex flex-col gap-4">
          <Formik
            initialValues={{
              language: t('CFG_COBRAND_EMAIL_TEMPLATE_LANGUAGE'),
            }}
            onSubmit={async (values) => null}
          >
            <Input
              type="select"
              label={t('general.language')}
              name="language"
              settings={{
                options: languageCodes,
                id: 'languageCode',
                placeholder: t('message.pleaseSelectAnOption'),
                onChange: (value: any) => void handleLanguageChange(value),
              }}
            />
          </Formik>
        </div>
      )}
    </>
  );

  const renderAddress = () => (
    <>
      {(!addresses || addresses.length === 0) && (
        <div className="font-nunito text-center text-base text-gray-600">
          {t('message.noAddressesAddedYet')}
        </div>
      )}
      {addresses &&
        addresses.map((address, index) => (
          <div
            key={index}
            className="font-nunito mb-4 flex items-center justify-between rounded-md border border-gray-300 px-4 py-4 text-sm text-gray-500"
          >
            <div>
              <strong className="text-base text-black">
                {address?.label} {address?.isDefault ? '(Default)' : ''}{' '}
              </strong>
              {printAddress(address)}
            </div>
            <div
              className="bg-primary-600 hover:bg-primary-800 rounded-lg px-3 py-2"
              onClick={() => 0}
            >
              <PencilIcon
                className="h-5 w-5 cursor-pointer text-white"
                onClick={() => {
                  setSelectedAddress(address);
                  setAddressModal('edit');
                }}
              />
            </div>
          </div>
        ))}
    </>
  );

  const renderShopifyHistory = () => (
    <>
      <div className="flex items-center gap-4">
        <ChevronButton
          className="flex h-full cursor-pointer flex-wrap content-center"
          kind="back"
          onClick={() => setStep('default')}
        />
        <div>
          <img
            src={shopifyLogo}
            alt="shopify company logo"
            className="mb-1 h-8"
          />
          <span className="font-nunito text-base text-gray-500">
            {t('general.historyLog')}
          </span>
        </div>
      </div>
      <div className="mt-8">
        <Table
          columns={historyTableHeaders}
          data={historyData}
          initialState={{ showGlobalFilter: true }}
          muiSearchTextFieldProps={{
            placeholder: t('message.searchByProductNameOrEmail'),
          }}
          state={{
            isLoading: false,
          }}
        />
      </div>
    </>
  );

  useEffect(() => {
    if (user?.addresses || tenant?.addresses)
      setAddresses(user ? user.addresses : tenant?.addresses);
  }, [user, tenant]);

  if (!currentData.identity) return <LoadingSpinner size="sm" />;

  return (
    <>
      {step === 'default' && (
        <div className="flex flex-col items-center gap-2">
          <Heading kind="h2">{t('general.information')}</Heading>
          <p className="font-nunito text-lg text-gray-500">
            {t('general.infoAboutYourOrganization')}
          </p>
        </div>
      )}
      {step !== 'shopify' ? (
        <div className="mx-auto mt-8 lg:w-[860px]">
          <CardWrapperWithHeader
            title={
              step === 'default'
                ? kind === 'tenant'
                  ? t('general.myOrganization')
                  : t('general.myAccount')
                : t('general.addresses')
            }
            goBack={step !== 'default' ? () => setStep('default') : undefined}
            headerRight={
              step === 'address' && (
                <Button
                  icon={<PlusIcon />}
                  kind="primary"
                  onClick={() => setAddressModal('new')}
                >
                  {t('general.addAddress')}
                </Button>
              )
            }
          >
            {step === 'default' && renderDefault()}
            {step === 'address' && renderAddress()}
          </CardWrapperWithHeader>
        </div>
      ) : (
        <div className="mx-auto mt-8">{renderShopifyHistory()}</div>
      )}
      {addressModal && (
        <EditAddAddressModal
          handleClose={() => setAddressModal(undefined)}
          mode={addressModal}
          kind={user ? 'user' : 'tenant'}
          address={selectedAddress}
          handleSubmit={handleAddressModalSubmit}
          handleDelete={handleAddressDelete}
        />
      )}
      {confirmDeleteModal && (
        <Modal
          className="h-fit w-[350px] rounded-md bg-white"
          kind={'pop-up'}
          settings={confirmationModalSettings}
        />
      )}
      {editNameModal && (
        <EditNameModal
          handleClose={() => setEditNameModal(false)}
          user={user || undefined}
        />
      )}
      {passwordModal && (
        <ChangePasswordModal
          handleClose={() => setPasswordModal(false)}
          user={user || undefined}
        />
      )}
      {phoneModal && (
        <ChangePhoneModal
          handleClose={() => setPhoneModal(false)}
          user={user || undefined}
        />
      )}
    </>
  );
}
